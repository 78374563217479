<template>
    <div class="page-layout-wrapper">
        <div class="container">
            <PageHeader
                title="Edit User"
            />

            <div class="row justify-content-center">
                <div class="col-md-8 col-lg-6">
                    <form @submit.prevent="onFormSubmit">
                        <div class="form-elements">
                            <div class="form-group">
                                <label for="id">User ID</label>
                                <input type="text" class="form-control" id="id" disabled :value="user.id">
                            </div>
                            <div class="form-group">
                                <label for="name">Name</label>
                                <input type="text" :class="[{'is-invalid': (validation_errors.name != '')},'form-control']" id="name" v-model="user.name">
                                <div v-if="validation_errors.name" class="invalid-feedback">{{validation_errors.name}}</div>
                            </div>
                            <div class="form-group">
                                <label for="email">Email address</label>
                                <input type="email" :class="[{'is-invalid': (validation_errors.email != '')},'form-control']" id="email" v-model="user.email">
                                <div v-if="validation_errors.email" class="invalid-feedback">{{validation_errors.email}}</div>
                            </div>
                            <div class="form-group">
                                <label for="website">Website</label>
                                <input type="website" :class="[{'is-invalid': (validation_errors.website != '')},'form-control']" id="website" v-model="user.website">
                                <div v-if="validation_errors.website" class="invalid-feedback">{{validation_errors.website}}</div>
                            </div>
                            <!--
                            <div class="form-group">
                                <label for="password">Password</label>
                                <input :class="[{'is-invalid': (validation_errors.password != '')},'form-control']" v-model="user.password" type="password" id="password">
                                <div v-if="validation_errors.password" class="invalid-feedback">{{validation_errors.password}}</div>
                            </div>
                            <div class="form-group">
                                <label for="password_confirmation">Confirm Password</label>
                                <input :class="[{'is-invalid': (validation_errors.password_confirmation != '')},'form-control']" v-model="user.password_confirmation" type="password" id="password_confirmation">
                                <div v-if="validation_errors.password_confirmation" class="invalid-feedback">{{validation_errors.password_confirmation}}</div>
                            </div>
                             -->
                            <div class="form-group">
                                <label>Role</label>
                                <div class="custom-control custom-radio">
                                    <input class="custom-control-input" type="radio" name="user_role" id="admin_role" value="1" v-model="user.role_update">
                                    <label class="custom-control-label" for="admin_role">
                                        Admin
                                    </label>
                                </div>
                                <div class="custom-control custom-radio">
                                    <input class="custom-control-input" type="radio" name="user_role" id="user_role" value="2" v-model="user.role_update">
                                    <label class="custom-control-label" for="user_role">
                                        User
                                    </label>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Status</label>
                                <div class="custom-control custom-radio">
                                    <input class="custom-control-input" type="radio" name="user_enabled" id="user_enabled" value="1" v-model="user.enabled">
                                    <label class="custom-control-label" for="user_enabled">
                                        Enabled
                                    </label>
                                </div>
                                <div class="custom-control custom-radio">
                                    <input class="custom-control-input" type="radio" name="user_enabled" id="user_disabled" value="0" v-model="user.enabled">
                                    <label class="custom-control-label" for="user_disabled">
                                        Disabled
                                    </label>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="name">Last Updated</label>
                                <input type="text" class="form-control" id="name" disabled :value="getDate(user.updated_at)">
                            </div>
                            <div class="form-group">
                                <label for="name">Created</label>
                                <input type="text" class="form-control" id="name" disabled :value="getDate(user.created_at)">
                            </div>

                            <div class="form-group">
                                <button
                                    class="btn btn-block btn-primary"
                                    v-text="loading ? 'Saving...' : 'Save'"
                                />
                            </div>

                            <transition name="fade-fast">
                                <div class="form-elements__blocker" v-show="loading" />
                            </transition>
                        </div>
                    </form>

                    <div class="text-center mt-5">
                        <router-link to="/admin/users" class="pill-button pill-button--black">Return to User List</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment-timezone'

import PageHeader from '@/components/Page/PageHeader'

export default {
    name: 'User',
    components: {
        PageHeader
    },
    data: () => ({
        user: false,
        loading: false,
        validation_errors: {
            email: "",
            name: "",
            website: "",
            password: "",
            password_confirmation: ""
        }
    }),
    created() {
        this.$http
            .get('/admin/users/'+this.$route.params.user_id)
            .then(({ data }) => {
                this.user = data.user;
                this.setRole();
            });
    },
    methods: {
        setRole() {
            if(this.user.role_key == 'admin') {
              this.user.role_update = 1;
            }
            if(this.user.role_key == 'user') {
              this.user.role_update = 2;
            }
        },
        getDate(date){
            return moment(date).format("dddd, MMMM Do YYYY, h:mm:ss a");
        },
        onFormSubmit() {
            this.loading = true;
            this.validation_errors = {
                email: "",
                name: "",
                password: "",
                website: "",
                password_confirmation: ""
            };

            this.$http
                .post('/admin/users/update', {
                    user: this.user,
                }).then(({ data }) => {
                    if (data.status == 0) {
                        Object.entries(data.validation_errors).forEach( (el) => {
                            this.validation_errors[el[0]] = el[1].join(' ');
                        });
                    }
                    else {
                        this.$vs.notification({
                            position: 'top-center',
                            title: 'Success!',
                            text: 'User has been updated',
                        })
                    }

                    this.loading = false;
                });
        },
    }
}
</script>
